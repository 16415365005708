
.App {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: white;
}


.Header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    outline: 2px solid black;
    background-color: white;
    padding: 20px 10px;
    font-weight: bold;
}

.Header_main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.Header_logo {
    width: 40px;
    height: 40px;
    margin-right: 5px;
}