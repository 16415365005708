body {
  margin: 0;
  font-family: 'Inter', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: white; 
}

.css-1k23hlb-MuiButtonBase-root-MuiButton-root {
  color: #7b68ee;
  border-color: #7b68ee;
}

.H2 {
  display: flex;
  font-size: 1.1em;
  font-weight: bold;
}

.Box {
  text-align: center;
  padding-top: 200px;
  padding-bottom: 200px;
}

:root {
  --main-bg-color: #f0f1fa;
}

